import './App.css'
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Entry from './components/entry'
import Home from './components/home'
import Portfolio from './components/portfolio'
import Profile from './components/profile'
import Resume from './components/resume'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import Navbar from './components/navbar'

const themeDark = createTheme({
    palette: {
        background: {
            default: '#121212',
        },
        text: {
            primary: '#ffffff',
        },
    },
})

function App() {
    return (
        <ThemeProvider theme={themeDark}>
            <CssBaseline />
            <Router>
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/portfolio/:slug" element={<Entry />} />
                    <Route path="/resume" element={<Resume />} />
                </Routes>
            </Router>
        </ThemeProvider>
    )
}

export default App
