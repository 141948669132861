import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import sanityClient from '../client.js'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Typography from '@mui/material/Typography'
import { useMediaQuery } from '@mui/material'
import { PortableText } from '@portabletext/react'

export default function Resume() {
    const [allJobData, setAllJobs] = useState(null)
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

    function alternateLogo(job) {
        if (isMobile) {
            return (
                <TimelineOppositeContent sx={{ m: 'auto 0', flex: 0.1 }}>
                    <img
                        width={'100px'}
                        className="company-logo"
                        src={`${job.logo.asset.url}?fit=max&auto=format`}
                        alt={job.name}
                        radius={50}
                    />
                </TimelineOppositeContent>
            )
        } else {
            return <></>
        }
    }

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "job"] | order(order asc){
        title,
        name,
        description,
        richdescription,
        startDate,
        endDate,
        logo{
          asset->{
          _id,
          url
        }
      }
    }`
            )
            .then((data) => {
                setAllJobs(data)
            })
            .catch(console.error)
    }, [])

    return (
        <div>
            <div className="resume-heading">
                <h1 className="section-heading">Résumé</h1>
                <h3 className="section-subheading text-muted">
                    My work experience.
                </h3>
            </div>

            <Timeline
                position={isMobile ? 'right' : 'alternate'}
                sx={{ m: 'auto', maxWidth: '1000px' }}
            >
                {allJobData &&
                    allJobData.map((job, index) => (
                        <TimelineItem key={index}>
                            {alternateLogo(job)}
                            <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot>
                                    <img
                                        width={isMobile ? '100%' : '100px'}
                                        className="company-logo"
                                        src={`${job.logo.asset.url}?fit=max&auto=format`}
                                        alt={job.name}
                                        radius={50}
                                    />
                                </TimelineDot>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography
                                    className="timeline-date"
                                    variant="h6"
                                >
                                    {format(
                                        new Date(job.startDate),
                                        'MMMM yyyy'
                                    )}{' '}
                                    -{' '}
                                    {(job.endDate &&
                                        format(
                                            new Date(job.endDate),
                                            'MMMM yyyy'
                                        )) ||
                                        'Present'}
                                </Typography>
                                <Typography variant="h6">
                                    {job.title}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {job.name}
                                </Typography>
                                <Typography>
                                    <PortableText value={job.richdescription} />
                                </Typography>
                            </TimelineContent>
                        </TimelineItem>
                    ))}
            </Timeline>
        </div>
    )
}
