import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import sanityClient from '../client.js'
import { CircularProgress, Grid, ImageList } from '@mui/material'
import ImageListItem, {
    imageListItemClasses,
} from '@mui/material/ImageListItem'

export default function Portfoliio() {
    const [allEntriesData, setAllEntries] = useState(null)

    function initialPage() {
        return (
            <Grid
                container
                spacing={0}
                align="center "
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress sx={{ m: 'auto' }} />
            </Grid>
        )
    }

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "entry"] | order(order asc){
          title,
          date,
          description,
          slug,
          imageThumb{
              asset->{
              _id,
              url
          }
        }}`
            )
            .then((data) => {
                setAllEntries(data)
            })
            .catch(console.error)
    }, [])

    if (!allEntriesData) return initialPage()

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <div className="resume-heading">
                <h1 className="section-heading">Photography</h1>
                <h3 className="section-subheading text-muted">
                    Some snaps I&apos;ve taken.
                </h3>
            </div>
            <div className="portfolio-container">
                <ImageList
                    spacing={30}
                    sx={{
                        display: 'grid',
                        overflow: 'visible',
                        gridTemplateColumns: {
                            xs: 'repeat(2, 1fr) !important',
                            sm: 'repeat(3, 1fr) !important',
                            md: 'repeat(3, 1fr) !important',
                            lg: 'repeat(4, 1fr) !important',
                            xl: 'repeat(4, 1fr) !important',
                        },
                        width: {
                            xs: 400,
                            sm: 600,
                            md: 900,
                            lg: 1200,
                            xl: 1500,
                        },
                        [`& .${imageListItemClasses.root}`]: {
                            display: 'flex',
                            flexDirection: 'column',
                        },
                    }}
                >
                    {allEntriesData &&
                        allEntriesData.map((entry) => (
                            <ImageListItem key={entry.slug.current}>
                                <Link
                                    to={'/portfolio/' + entry.slug.current}
                                    key={entry.slug.current}
                                >
                                    <img
                                        height={'100%'}
                                        width={'100%'}
                                        src={`${entry.imageThumb.asset.url}?auto=format`}
                                        alt={entry.title}
                                        loading="lazy"
                                    />
                                </Link>
                            </ImageListItem>
                        ))}
                </ImageList>
            </div>
        </Grid>
    )
}
