import { Container, Grid } from '@mui/material'
import React, { useCallback } from "react";
import { TextLoop } from 'react-text-loop-next'
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";

export default function Home() {
    const particlesInit = useCallback(async (engine) => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container) => {
        await console.log(container);
    }, []);
    return (
        <Container className="hero hero-intro">
            <Particles
                init={particlesInit}
                loaded={particlesLoaded}
                style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                }}
                params={{
                    particles: {
                        color: {
                            value: '#fff',
                        },
                        move: {
                            direction: 'top',
                            enable: true,
                            outModes: 'out',
                            speed: 0.7,
                            random: true,
                        },
                        number: {
                            density: {
                                enable: false,
                            },
                            value: 500,
                        },
                        line_linked: {
                            enable: false,
                        },
                        size: {
                            value: 2,
                            random: true,
                            anim: {
                                speed: 4,
                                size_min: 0.3,
                            },
                        },
                    },
                    interactivity: {
                        events: {
                            onhover: {
                                enable: true,
                                mode: 'connect',
                            },
                            onclick: {
                                enable: true,
                                mode: 'repulse',
                            },
                        },
                        modes: {
                            repulse: {
                                distance: 100,
                                duration: 2,
                            },
                        },
                    },
                }}
            />
            <Grid
                container
                sx={{
                    padding: {
                        xs: '25px',
                        sm: '75px 50px',
                        md: '100px 100px',
                        lg: '150px 75px',
                        xl: '150px 50px',
                    },
                }}
                className="grid"
            >
                <Grid className="hero-box" item xs={12}>
                    <div className="hero-subtext">Hello, I&apos;m</div>
                    <div className="hero-text">
                        Emilio<span className="dot-blue">.</span>
                    </div>
                    <div>
                        <TextLoop className="hero-text text-loop">
                            <span>Full Stack Dev</span>
                            <span>Tech Lead</span>
                            <span>Engineer</span>
                            <span>Photographer</span>
                        </TextLoop>{' '}
                    </div>
                    <h1 className="hero-subtext">Based in San Francisco.</h1>
                </Grid>
            </Grid>
        </Container>
    )
}
