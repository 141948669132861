import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import MenuItem from '@mui/material/MenuItem'
import { Link } from 'react-router-dom'
import { GitHub, Instagram, LinkedIn } from '@mui/icons-material'

const pages = [
    { text: 'About', route: 'profile' },
    { text: 'Résumé', route: 'resume' },
    { text: 'Photography', route: 'portfolio' },
]

const SocialShare = [
    { Social: <LinkedIn />, link: 'https://www.linkedin.com/in/emiliozand' },
    { Social: <Instagram />, link: 'https://www.instagram.com/ezmang' },
    { Social: <GitHub />, link: 'https://github.com/EmilioZand' },
]

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null)

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    return (
        <AppBar
            style={{ background: 'transparent', boxShadow: 'none' }}
            position="static"
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                color: 'white',
                                mr: 2,
                                display: { xs: 'none', sm: 'flex' },
                            }}
                        >
                            Emilio Zand
                        </Typography>
                    </Link>
                    <Link to={'/'} style={{ textDecoration: 'none' }}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                color: 'white',
                                flexGrow: 1,
                                display: { xs: 'flex', sm: 'none' },
                            }}
                        >
                            Emilio Zand
                        </Typography>
                    </Link>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', sm: 'flex' },
                        }}
                    >
                        {pages.map((page) => (
                            <Link
                                to={'/' + page.route}
                                style={{ textDecoration: 'none' }}
                                key={page.route}
                            >
                                <Button
                                    onClick={handleCloseNavMenu}
                                    sx={{
                                        my: 2,
                                        color: 'white',
                                        display: 'block',
                                    }}
                                >
                                    {page.text}
                                </Button>
                            </Link>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }}>
                        {SocialShare.map((val, i) => (
                            <Button
                                key={i}
                                href={val.link}
                                sx={{ my: 2, color: 'white' }}
                            >
                                {val.Social}
                            </Button>
                        ))}
                        <IconButton
                            sx={{ display: { sm: 'none' } }}
                            size="large"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <Link
                                    to={'/' + page.route}
                                    style={{ textDecoration: 'none' }}
                                    key={page.route}
                                >
                                    <MenuItem onClick={handleCloseNavMenu}>
                                        <Typography
                                            color="blaack"
                                            textAlign="center"
                                        >
                                            {page.text}
                                        </Typography>
                                    </MenuItem>
                                </Link>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}
export default ResponsiveAppBar
