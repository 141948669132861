import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import sanityClient from '../client.js'
import imageUrlBuilder from '@sanity/image-url'
import { CircularProgress, Grid, MenuItem, MenuList } from '@mui/material'

const builder = imageUrlBuilder(sanityClient)

function urlFor(source) {
    builder.fit('max')
    return builder.image(source)
}

function initialPage() {
    return (
        <div>
            <MenuList>
                <MenuItem component={Link} to={'/Portfolio'}>
                    &larr; Back to Portfolio
                </MenuItem>
            </MenuList>
            <Grid
                container
                spacing={0}
                align="center"
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <CircularProgress sx={{ m: 'auto' }} />
            </Grid>
        </div>
    )
}

export default function Entry() {
    const [entryData, setEntryData] = useState(null)
    const { slug } = useParams()

    useEffect(() => {
        sanityClient
            .fetch(
                `*[slug.current == $slug]{
          title,
          slug,
          description,
          date,
          image{
            asset->{
              _id,
              url
             }
           }
       }`,
                { slug }
            )
            .then((data) => setEntryData(data[0]))
            .catch(console.error)
    }, [slug])

    if (!entryData) return initialPage()

    return (
        <div>
            <MenuList>
                <MenuItem component={Link} to={'/Portfolio'}>
                    &larr; Back to Portfolio
                </MenuItem>
            </MenuList>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <div>
                    <h2>{entryData.title}</h2>
                </div>
                <img
                    className="entry-image"
                    src={urlFor(entryData.image).url()}
                    alt={entryData.description}
                    loading="lazy"
                />
                <div>
                    <h4>{entryData.description}</h4>
                </div>
            </Grid>
        </div>
    )
}
