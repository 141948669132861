import React from 'react'
import {
    Box,
    Container,
    Grid,
    Tab,
    Card,
    CardHeader,
    CardContent,
    Divider,
} from '@mui/material'
import { Book, Laptop } from '@mui/icons-material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import Headshot from '../assets/images/headshot.jpg'
import ReactLogo from '../assets/images/react.png'
import Angular from '../assets/images/angular.png'
import HTML5 from '../assets/images/html5.png'
import Node from '../assets/images/nodejs.png'
import Javascript from '../assets/images/javascript.png'
import Typescript from '../assets/images/typescript.png'
import Rails from '../assets/images/rails.png'
import Scala from '../assets/images/scala.png'
import Laravel from '../assets/images/laravel.png'
import Java from '../assets/images/java.png'
import Postgresql from '../assets/images/postgresql.png'
import Mongodb from '../assets/images/mongodb.png'
import Python from '../assets/images/python.png'

export default function Profile() {
    const [value, setValue] = React.useState('1')

    function handleChange(event, newValue) {
        setValue(newValue)
    }

    return (
        <Container>
            <div className="resume-heading">
                <h1 className="section-heading">About Me</h1>
                <h3 className="section-subheading text-muted">
                    A bit about myself.
                </h3>
            </div>
            <Grid textAlign={'center'} container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Book style={{ fontSize: 100 }} />
                    <p>
                        A graduate of the University of British Columbia in
                        Vancouver, with a BASc in Computer Engineering (Software
                        Option).
                        <br />
                        <br />
                        Attended school in San Francisco prior to University,
                        spending the majority of my time learning Mathematics,
                        Mandarin and Science.
                    </p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <img
                        width="50%"
                        className="company-logo"
                        src={Headshot}
                        alt={'headshot'}
                        radius={50}
                    />
                    <p>
                        San Francisco native, self-prescribed nerd, temporarily
                        displaced to the Great White North in Vancouver. I love
                        sports, games, photography, music, and code.
                    </p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Laptop style={{ fontSize: 100 }} />
                    <p>
                        Currently working as a Partner Technical Lead at SigFig.
                        I have worked at several health tech and fintech
                        startups as a full-stack Engineer.
                        <br />
                        <br />
                        Proficient in a multitude of languages and platforms,
                        including: React, Scala, Javascript, Angular, Laravel,
                        Python and more.
                    </p>
                </Grid>
            </Grid>
            <TabContext value={value}>
                <Box
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                        },
                        borderBottom: 1,
                        borderColor: 'white',
                    }}
                >
                    <TabList
                        textColor="inherit"
                        indicatorColor="primary"
                        onChange={handleChange}
                        aria-label="About me tabs"
                        centered
                    >
                        <Tab
                            label={<span className="tab-label">Skills</span>}
                            value="1"
                        />
                        <Tab
                            label={<span className="tab-label">Education</span>}
                            value="2"
                        />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Card className="skill-card">
                        <CardHeader title="Front End" titleColor="#5f99ff" />
                        <Divider color="#FFF" />
                        <CardContent>
                            <Grid container spacing={10}>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="React"
                                        src={ReactLogo}
                                    />
                                    React
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Angular"
                                        src={Angular}
                                    />
                                    Angular
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="HTML5"
                                        src={HTML5}
                                    />
                                    HTML5
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Javascript"
                                        src={Javascript}
                                    />
                                    Javascript
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Node"
                                        src={Node}
                                    />
                                    Node
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Typescript"
                                        src={Typescript}
                                    />
                                    Typescript
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className="skill-card">
                        <CardHeader title="Back End" titleColor="#5f99ff" />
                        <Divider color="#FFF" />
                        <CardContent>
                            <Grid container spacing={10}>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Scala"
                                        src={Scala}
                                    />
                                    Scala
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Laravel"
                                        src={Laravel}
                                    />
                                    Laravel
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Java"
                                        src={Java}
                                    />
                                    Java
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Rails"
                                        src={Rails}
                                    />
                                    Rails
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card className="skill-card">
                        <CardHeader title="Misc" titleColor="#5f99ff" />
                        <Divider color="#FFF" />
                        <CardContent>
                            <Grid container spacing={10}>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="Python"
                                        src={Python}
                                    />
                                    Python
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="MongoDB"
                                        src={Mongodb}
                                    />
                                    MongoDB
                                </Grid>
                                <Grid item className="skill">
                                    <img
                                        className="skills-logo"
                                        alt="PostgreSQL"
                                        src={Postgresql}
                                    />
                                    PostgreSQL
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </TabPanel>
                <TabPanel value="2">
                    <div>
                        <h4>University of British Columbia</h4>
                        <p>
                            Bachelor of Science in Computer Engineering
                            (Software Option)
                        </p>
                        <p>Vancouver, BC</p>
                    </div>
                    <br />
                    <div>
                        <h4>Lick Wilmerding High School</h4>
                        <p>San Francisco, CA</p>
                    </div>
                    <br />
                    <div>
                        <h4>Chinese American International School (K-8)</h4>
                        <p>San Francisco, CA</p>
                    </div>
                </TabPanel>
            </TabContext>
        </Container>
    )
}
